<template>
  <SvgIcon
    :src="icon"
    class="mm-indicator-progress"
  />
</template>

<script lang="ts" setup>
import SvgIcon from './SvgIcon.vue';
import { EIconPath } from '../enums/iconPath.enum';

const props = defineProps<{
  light?: boolean;
  dark?: boolean;
  size?: number;
}>();

const icon = computed(
  () => {
    if (props.light) {
      return EIconPath.IndicatorsProgressLightSvg;
    }

    if (props.dark) {
      return EIconPath.IndicatorsProgressDarkSvg;
    }

    return EIconPath.IndicatorsProgressSvg;
  },
);
</script>

<style lang="scss" scoped>
.mm-indicator-progress {
  animation-delay: 0s;
  animation-name: loader;
  animation-duration:  0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
